<template>
  <div>
    <!-- Controllers -->
    <div
      color="white"
      class="d-flex justify-end py-4 pr-6"
    >
      <v-btn
        v-if="isUserManager && !teams.length" class="mr-3" color="primary" outlined
        @click="$confirm($t('club.confirmDelete')).then(confirm => confirm && remove())"
        v-text="$t('common.delete')"
      />
      <v-btn
        v-if="isUserManager" outlined color="primary" dark depressed class="mr-3"
        @click="showMergeWithOtherClubDialog = true" v-text="$t('club.editClub.mergeWithOtherClub')"
      />
      <v-btn color="primary" type="submit" class="mr-3" dark depressed @click.prevent="update()" v-text="$t('common.save')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 px-md-10 pt-0 pb-6">
      <v-row class="my-0">
        <v-col cols="12" md="8" class="py-0">
          <v-row>
            <v-col class="py-1 px-3" cols="12" sm="6">
              <div class="mb-3 d-flex">
                <h2 class="mr-3" v-text="$t('common.logo')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '1:1',
                    width: '300px',
                    height: '300px'
                  })"
                />
              </div>
              <image-field
                v-model="form.logo"
                :error-messages="getFieldErrors('logo')"
                :width="300" :height="300"
                suffix="_logo"
                @input="$v.form.logo.$touch()"
              />
            </v-col>
          </v-row>
          <br><br><br>
          <v-row v-if="project.features.clubPrice">
            <v-col class="py-1 px-3" cols="12" sm="6">
              <h2 class="mb-3" v-text="$t('club.payments.title')" />
              <v-select
                v-model="form.paymentStatus"
                :class="`select-status--${form.paymentStatus}`"
                :items="paymentStates"
                :disabled="!isUserManager"
                :background-color="getPaymentStatusBackGroundColor(form.paymentStatus)"
                :label="$t('club.payments.title')"
                append-icon="mdi-chevron-down"
                outlined
                dense
                @blur="$v.form.paymentStatus.$touch()"
              />
            </v-col>
            <v-col v-if="isUserManager" class="py-1 px-3" cols="12">
              <v-textarea
                v-model="form.paymentComments"
                :placeholder="$t('club.payments.notesOnPayment')"
                outlined
                dense
                class="mt-1"
                height="100"
                @blur="$v.form.paymentComments.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isUserProjectStaff && hasCollaborator">
            <v-col class="py-1 px-3" cols="12" sm="12">
              <h2 class="mb-3" v-text="$t('club.editClub.linkAccess')" />
              <v-text-field
                :label="$t('common.link')"
                outlined
                dense
                readonly
                disabled
                :value="collaboratorUserLoginLink"
              >
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="copyToClipboard(collaboratorUserLoginLink)">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('club.editClub.copyLink') }}
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-text-field
                :label="$t('club.editClub.password')"
                outlined
                readonly
                disabled
                dense
                :value="collaboratorUserPassword"
              >
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="copyToClipboard(collaboratorUserPassword)">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('club.editClub.copyPassword') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-9 mb-9">
            <v-col cols="12">
              <h2 v-text="$t('project.sections.generalInformation')" />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.name" :label="$t('club.editClub.nameClubSchool')"
                :error-messages="getFieldErrors('name')"
                outlined dense @blur="$v.form.name.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.legalName" :label="$t('club.editClub.socialDenomination')"
                :error-messages="getFieldErrors('legalName')"
                outlined dense @blur="$v.form.legalName.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.legalEntityId" :label="$t('club.editClub.cif/nif')"
                :error-messages="getFieldErrors('legalEntityId')"
                outlined dense @blur="$v.form.legalEntityId.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.website" :label="`${$t('club.editClub.webPage')}  ${$t('common.optional')}`"
                :error-messages="getFieldErrors('website')"
                outlined dense @blur="$v.form.website.$touch()"
              >
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="copyToClipboard(form.website)">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('club.editClub.copyLink') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-switch
                v-model="form.isFederated" :label="$t('club.editClub.isFederated')"
                @change="$v.form.isFederated.$touch()"
              />
            </v-col>
            <v-col v-if="form.isFederated" cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.federationPlace" :label="$t('club.editClub.whereIsFederated')"
                :error-messages="getFieldErrors('federationPlace')"
                outlined dense @blur="$v.form.federationPlace.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-for="(staffer, index) in form.staff" :key="index">
            <v-col cols="12" class="d-flex align-center justify-space-between">
              <h2 v-text="`${$t('club.staff')} ${index + 1}`" />
              <v-btn v-if="form.staff.length > 1"
                     class="w-auto" icon small color="primary" @click="form.staff.splice(index, 1)"
              >
                <span>{{ $t('common.delete') }}</span>
                <v-icon dark class="mx-1">
                  delete_outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="staffer.firstName" :label="$t('user.fields.firstName')"
                :error-messages="getFieldErrors(`staff.$each.$iter.${index}.firstName`)"
                outlined dense @blur="$v.form.staff.$each[index].firstName.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="staffer.lastName" :label="$t('user.fields.lastName')"
                :error-messages="getFieldErrors(`staff.$each.$iter.${index}.lastName`)"
                outlined dense @blur="$v.form.staff.$each[index].lastName.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="staffer.email" :label="$t('user.fields.email')"
                :error-messages="getFieldErrors(`staff.$each.$iter.${index}.email`)"
                outlined dense @blur="$v.form.staff.$each[index].email.$touch()"
              >
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="copyToClipboard(staffer.email)">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('club.editClub.copyLink') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <phone-field
                v-model="staffer.phone" outlined dense
                :error-messages="getFieldErrors(`staff.$each.$iter.${index}.phone.code`) || getFieldErrors(`staff.$each.$iter.${index}.phone.number`)"
                @blur="$v.form.staff.$each[index].phone.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="staffer.position" :label="$t('club.editClub.charge')"
                :error-messages="getFieldErrors(`staff.$each.$iter.${index}.position`)"
                outlined dense @blur="$v.form.staff.$each[index].position.$touch()"
              />
            </v-col>
            <v-col v-if="index === form.staff.length - 1" cols="12">
              <v-btn class="w-auto" icon small color="primary" @click="form.staff.push({
                firstName: null, lastName: null, position: null,
                phone: { country: null, code: null, number: null}, email: null,
              })"
              >
                <v-icon dark class="mx-1">
                  add_box
                </v-icon>
                <span>{{ $t('club.editClub.addOtherStaff') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-9">
            <v-col cols="12">
              <h2 v-text="$t('club.editClub.clubAddress')" />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.line1" :label="$t('common.addressLine1')"
                :messages="$t('common.street')" :error-messages="getFieldErrors('address.line1')"
                outlined dense @blur="$v.form.address.line1.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.line2" :label="$t('common.addressLine2')"
                :messages="$t('common.additionalInformationStreet')" :error-messages="getFieldErrors('address.line2')"
                outlined dense @blur="$v.form.address.line2.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.zip" :label="$t('common.postalCode')"
                :error-messages="getFieldErrors('address.zip')"
                outlined dense @blur="$v.form.address.zip.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.city" :label="$t('common.city')"
                :error-messages="getFieldErrors('address.city')"
                outlined dense @blur="$v.form.address.city.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.region" :label="$t('common.stateProvinceRegion')"
                :error-messages="getFieldErrors('address.region')"
                outlined dense @blur="$v.form.address.region.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-select
                v-model="form.address.country" :items="countryItems"
                :label="$t('user.fields.country')" :error-messages="getFieldErrors('address.country')"
                outlined dense hide-details="auto" append-icon="mdi-chevron-down"
              />
            </v-col>
          </v-row>
          <v-row class="mt-9">
            <v-col cols="12">
              <h2 v-text="$t('club.editClub.equipement')" />
            </v-col>
            <v-col v-for="(index) in [0, 1]" :key="index" cols="12" sm="6" class="py-1 px-3">
              <v-row>
                <v-col cols="12">
                  <h3 v-text="`${index + 1}.º ${$t('club.editClub.equipement')}`" />
                </v-col>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-select
                        v-model="form.kitsByIndex[index].jersey.type" :items="jerseyStyles"
                        :error-messages="getFieldErrors(`kitsByIndex.${index}.jersey.type`)"
                        :label="$t('club.editClub.tShirtPrint')" outlined dense append-icon="mdi-chevron-down"
                        @blur="$v.form.kitsByIndex[index].jersey.type.$touch()"
                      />
                    </v-col>
                    <v-col cols="6">
                      <span>{{ $t('club.editClub.jerseyColor') }}</span>
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex">
                        <color-field
                          v-model="form.kitsByIndex[index].jersey.color1" class="mr-4"
                          :error-messages="getFieldErrors(`kitsByIndex.${index}.jersey.color1`)"
                          @blur="$v.form.kitsByIndex[index].jersey.color1.$touch()"
                        />
                        <color-field
                          v-if="form.kitsByIndex[index].jersey.type && form.kitsByIndex[index].jersey.type !== 'solid'"
                          v-model="form.kitsByIndex[index].jersey.color2"
                          :error-messages="getFieldErrors(`kitsByIndex.${index}.jersey.color2`)"
                          @blur="$v.form.kitsByIndex[index].jersey.color2.$touch()"
                        />
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <span>{{ $t('club.editClub.pantsColor') }}</span>
                    </v-col>
                    <v-col cols="6">
                      <color-field
                        v-model="form.kitsByIndex[index].pants.color1" class="mr-2"
                        :error-messages="getFieldErrors(`kitsByIndex.${index}.pants.color1`)"
                        @blur="$v.form.kitsByIndex[index].pants.color1.$touch()"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-9">
            <v-col cols="12">
              <h2 v-text="$t('club.accomodation.title')" />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-switch
                v-model="form.requiresAccomodation" :label="$t('club.editClub.activeAccomodationOffers')"
                @change="$v.form.requiresAccomodation.$touch()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
    <merge-with-other-club-dialog
      v-if="showMergeWithOtherClubDialog"
      :club-id="club.id" :organization-id="organizationId" :project-id="projectId" @closeDialog="showMergeWithOtherClubDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { email, required, requiredIf, url } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import localeUtils from '@/utils/mixins/localeUtils'
import getBasePath from '@/utils/getBasePath'
import saveDialogUtils from '@/utils/mixins/saveDialog'
import i18nCountries from '@/utils/mixins/i18nCountries'
import clubJerseyStylesEnum from '@/enums/clubJerseyStylesEnum'

export default {
  name: 'EditClub',
  components: {
    ImageField: () => import('@/components/formFields/ImageField'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    PhoneField: () => import('@/components/formFields/PhoneField'),
    ColorField: () => import('@/components/formFields/ColorField'),
    MergeWithOtherClubDialog: () => import('@/modules/club/MergeWithOtherClubDialog'),
  },
  mixins: [localeUtils, formUtils, saveDialogUtils, i18nCountries],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    clubId: { type: String, required: true },
  },
  data() {
    return {
      jerseyStyles: clubJerseyStylesEnum,
      form: {},
      showMergeWithOtherClubDialog: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ club: 'club/data' }),
    ...mapGetters({ isUserProjectStaff: 'project/isUserStaff' }),
    ...mapGetters('project', ['isUserManager']),
    ...mapGetters({ teams: 'club/teams' }),
    hasCollaborator: ({ club }) => !!club.collaboratorUser,
    collaboratorUserLoginLink: ({ organizationId, projectId, clubId }) => `${getBasePath()}/login-club/${organizationId}/${projectId}/${clubId}`,
    collaboratorUserPassword: ({ club }) => club.collaboratorUser.password,
    paymentStates() {
      return [
        { text: this.$t('club.payments.notRequested'), value: 'notRequested' },
        { text: this.$t('club.payments.requested'), value: 'requested' },
        { text: this.$t('club.payments.complete'), value: 'complete' },
      ]
    },
  },
  validations() {
    return {
      form: {
        logo: { required },
        paymentStatus: {},
        paymentComments: {},
        name: { required },
        legalName: { required },
        legalEntityId: { required },
        website: { url },
        isFederated: {},
        federationPlace: { required: requiredIf(() => this.form.isFederated) },
        staff: {
          required,
          $each: {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            phone: { code: { required }, number: { required } },
            position: { required },
          },
        },
        address: {
          line1: { required },
          line2: {},
          zip: { required },
          city: { required },
          region: { required },
          country: { required },
        },
        kitsByIndex: {
          0: {
            jersey: {
              color1: { required },
              color2: { required: requiredIf(() => this.form.kitsByIndex[0].jersey.type !== 'solid') },
              type: { required },
            },
            pants: {
              color1: { required },
            },
          },
          1: {
            jersey: {
              color1: { required },
              color2: { required: requiredIf(() => this.form.kitsByIndex[1].jersey.type !== 'solid') },
              type: { required },
            },
            pants: {
              color1: { required },
            },
          },
        },
        requiresAccomodation: {},
      },
    }
  },
  created() {
    this.form = {
      logo: this.club.logo ?? null,
      paymentStatus: this.club.paymentStatus ?? null,
      paymentComments: this.club.paymentComments ?? null,
      name: this.club.name ?? null,
      legalName: this.club.legalName ?? null,
      legalEntityId: this.club.legalEntityId ?? null,
      website: this.club.website ?? null,
      isFederated: this.club.isFederated ?? false,
      federationPlace: this.club.federationPlace ?? null,
      staff: this.club.staff?.length ? [...this.club.staff.map(s => ({ ...s }))] : [{
        firstName: null,
        lastName: null,
        email: null,
        phone: { country: null, code: null, number: null },
        position: null,
      }],
      address: {
        line1: this.club.address?.line1 ?? null,
        line2: this.club.address?.line2 ?? null,
        zip: this.club.address?.zip ?? null,
        city: this.club.address?.city ?? null,
        region: this.club.address?.region ?? null,
        country: this.club.address?.country ?? null,
      },
      kitsByIndex: {
        0: {
          jersey: {
            color1: this.club.kitsByIndex?.[0]?.jersey?.color1 ?? null,
            color2: this.club.kitsByIndex?.[0]?.jersey?.color2 ?? null,
            type: this.club.kitsByIndex?.[0]?.jersey?.type ?? null,
          },
          pants: {
            color1: this.club.kitsByIndex?.[0].pants?.color1 ?? null,
          },
        },
        1: {
          jersey: {
            color1: this.club.kitsByIndex?.[1]?.jersey?.color1 ?? null,
            color2: this.club.kitsByIndex?.[1]?.jersey?.color2 ?? null,
            type: this.club.kitsByIndex?.[1]?.jersey?.type ?? null,
          },
          pants: {
            color1: this.club.kitsByIndex?.[1]?.pants?.color1 ?? null,
          },
        },
      },
      requiresAccomodation: this.club.requiresAccomodation ?? false,
    }
  },
  methods: {
    async update() {
      const { organizationId, projectId, clubId } = this
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      await this.runAsync(() => this.$store.dispatch('club/update', { organizationId, projectId, clubId, data: this.parseForm() }))
      this.userAgreedToLeave = true
    },
    async remove() {
      const { organizationId, projectId, club } = this
      await this.runAsync(() => this.$store.dispatch('club/delete', { organizationId, projectId, club }))
      this.$router.push({ name: 'competition-clubs' })
    },
    parseForm() {
      const jersey0color2 = this.form.kitsByIndex[0].jersey.type === 'solid' ? null : this.form.kitsByIndex[0].jersey.color2
      const jersey1color2 = this.form.kitsByIndex[1].jersey.type === 'solid' ? null : this.form.kitsByIndex[1].jersey.color2
      return {
        ...this.form,
        website: this.form.website ? this.form.website.toLowerCase() : null,
        federationPlace: this.form.isFederated ? this.form.federationPlace : null,
        staff: this.form.staff.map(({ listId, ...s }) => ({ ...s, email: s.email.toLowerCase() })),
        kitsByIndex: {
          0: { ...this.form.kitsByIndex[0], jersey: { ...this.form.kitsByIndex[0].jersey, color2: jersey0color2 } },
          1: { ...this.form.kitsByIndex[1], jersey: { ...this.form.kitsByIndex[1].jersey, color2: jersey1color2 } },
        },
      }
    },
    getPaymentStatusBackGroundColor(status) {
      return {
        notRequested: this.$vuetify.theme.themes.light.grey05,
        requested: this.$vuetify.theme.themes.light.yellow10,
        complete: this.$vuetify.theme.themes.light.green10,
      }[status]
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .tiptap-vuetify-editor__content {
    max-height: 150px;
  }

  ::v-deep .select-status {
    &--notRequested .v-input__slot fieldset {
      border: 2px solid $grey40;
    }

    &--requested .v-input__slot fieldset {
      border: 2px solid $yelllow80;
    }

    &--complete .v-input__slot fieldset {
      border: 2px solid $green80;
    }
  }
</style>
